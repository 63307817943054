.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #f5f5f5;
    text-align: center;
    font-size: 14px;
}

.footer a {
    color: #333;
}
