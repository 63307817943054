.BeerRecipe {
    text-align: left;
    padding: 20px;
}

.BeerRecipe h1, h2, h3, h4, h5, h6 {
    padding-left: 10px;
    font-weight: 600;
}

.BeerRecipe td:first-child {
	font-weight: 400;
}

.BeerRecipe table {
    margin-bottom: 30px;
}