.header {
    margin-bottom: 20px;
}

.header .navbar-brand {
    font-weight: bold;
}

.header .alert {
    text-align: center;
}