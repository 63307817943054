.Log .alert {
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 100;
    box-shadow: 0 6px 12px 0px rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}

.Log .alertContainer {
    height: 0px;
}

.Log .deleteSpinnerContainer {
    width: 0px;
}

.Log .deleteSpinnerContainer span {
    position: relative;
    left: -20px;
    top: 0px;
    z-index: 50;
} 

.cap-button-group img {    
    width: 30px;
}