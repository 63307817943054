.BeerCard {
	text-align: center;
}

.BeerCard td {
	padding: 5px 15px;
	text-align: left;
}

.BeerCard td:first-child {
	font-weight: 600;
}

.BeerCard img {
	max-width: 50px;
	padding: 0px;	
	float: left;
}

.BeerCard .col-cap {
	padding: 0px;
}