@media all and (min-width: 480px) {
	.Login {
		margin: 0 auto;
		max-width: 320px;
	}
}

.Login .google-button {
	width: auto !important;
}


